import request from '_utils/request'

const get_product_list = () => {
  return request({
    url: '/api/product/getProductList',
    method: 'GET',
    params: {
      source: 2,
      timestamp: Date.now()
    }
  })
};

//点击产品
const click_product_item = (id) => {
  return request({
    url: '/api/product/addClick/' + id,
    method: 'GET',
    params: {
      isH5: 1
    }
  })
}

export {
  get_product_list,
  click_product_item
}